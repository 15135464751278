var api = "//api.qingcigame.com/thai";
var index = "";
var go_page = window.location.href.split("?page=")[1];
var obj = {
  index: function () {
    this.preloading(); // 预加载
    this.indexBind(); // 首页按钮
    this.downUrl(); //下载地址
    this.countNum(); //bl埋点
    this.getNumber();
  },
  // 图片资源预加载
  preloading: function () {
    //图片预加载
    var queue = new createjs.LoadQueue();
    queue.on(
      "progress",
      function (e) {
        //加载中的进度
        if (e.progress * 100 >= 10) {
          $(".loding_time span").width(parseInt(e.progress * 100) + "%");
        }
        var numbers = e.progress * 100;
      },
      this
    );
    queue.on(
      "complete",
      function (e) {
        //加载完成执行操作
        var _this = this;
        $(".loding_time").remove();
        $(".preloading").addClass("preloading-ani").fadeOut(1000);
        $(".index").addClass("index-show");
        this.indexSwiper();
        if (go_page == undefined) {
          // pv暂时未提供，先隐藏
          this.defaultVideo();
        }
        setTimeout(function () {
          $(".preloading").remove();
        }, 1000);
      },
      this
    );
    queue.loadManifest([
      //加载图片，不包括后台的
      "/official/images/pc/m_dt_sz-5967e7c14c.png",
      "/official/images/pc/m_dt_t-9a526eff70.png",
      "/official/images/pc/m_jdt-8cabd96c92.png",
      "/official/images/pc/header_bg-d1370c5756.png",
      "/official/images/pc/snail_ke-2d160add13.png",
      "/official/images/pc/snail-a6e6df3629.png",
      "/official/images/pc/index_bg-4da3a6776f.png",
    ]);
  },
  // 首页暂未开放的几个按钮
  indexBind: function () {
    $(".monitor-btn").on("click", function () {
      var mon_btn = $(this).attr("data-event");
      obj.countNum(mon_btn);
    });

    $(".flame").hover(
      function () {
        $(this).find("i").addClass("disappear");
      },
      function () {
        $(this).find("i").removeClass("disappear");
      }
    );

    $(".role-ani").click(function (event) {
      var _this = $(this);
      if (!_this.hasClass("rubberBand2")) _this.addClass("rubberBand2");
      setTimeout(function () {
        _this.removeClass("rubberBand2");
      }, 1000);
    });

    // 匹配机型 替换图标
    if (navigator.userAgent.match(/(iPhone|iPod|ios)/i)) {
      // $(".google-btn").attr("class", "app-btn")
      $(".google-btn").attr({ class: "app-btn", "data-event": "AppStore" });
    }

    // 30w奖励弹窗
    $(".award-01").on("click", function () {
      $(".wrap30").show();
    });

    // 40w奖励弹窗
    $(".award-02").on("click", function () {
      $(".wrap40").show();
    });

    // 50w奖励弹窗
    $(".award-03").on("click", function () {
      $(".wrap50").show();
    });

    // 60w奖励弹窗
    $(".award-05").on("click", function () {
      $(".wrap60").show();
    });

    // 80w奖励弹窗
    $(".award-06").on("click", function () {
      $(".wrap80").show();
    });

    // 关闭预约奖励弹窗
    $(".popup_box .btn").on("click", function () {
      $(".popup").hide();
    });
  },
  defaultVideo: function () {
    var src = "https://www.youtube.com/embed/Tf6i31R1SjI";
    $(".play-btn").fadeIn().attr("data", src);
    // $(".video-wrap, .popup-video")
    //   .fadeIn()
    //   .find("iframe")
    //   .attr("src", $(".play-btn").attr("data"))
    //   .removeClass("ani-btn");

    $(".play-btn").on("click", function (event) {
      obj.countNum("video-play");
      $(".video-wrap, .popup-video")
        .fadeIn()
        .find("iframe")
        .attr("src", $(".play-btn").attr("data"))
        .removeClass("ani-btn");
    });
    $(".popup").on("click", ".close-btn", function (event) {
      $(".video-wrap, .popup-video").fadeOut().find("iframe").attr("src", "");
      $(".leidian-wrap , .popup-leidian").fadeOut();
    });
  },
  // 获取预约人数
  getNumber: function () {
    $.ajax({
      url: api + "/reserve/number",
      type: "GET",
      data: {
        type: 1,
      },
      success: function (json) {
        if (json.code == 200) {
          if (json.data.total_number >= 2000000) {
            $(".map-box").addClass("got-05");
          } else if (json.data.total_number >= 1600000) {
            $(".map-box").addClass("got-04");
          } else if (json.data.total_number >= 1200000) {
            $(".map-box").addClass("got-03");
          } else if (json.data.total_number >= 800000) {
            $(".map-box").addClass("got-02");
          } else if (json.data.total_number >= 400000) {
            $(".map-box").addClass("got-01");
          }
        } else {
          layer.msg(
            '<span style="font-size: 1.8rem;">' + json.message + "</span>"
          );
        }
      },
    });
  },
  // 首页轮播
  indexSwiper: function () {
    //商店卡片轮播
    var mySwiper2 = new Swiper(".swiper-container-card", {
      loop: true,
      pagination: ".swiper-pagination2",
      paginationClickable: true,
      noSwiping: true,
      autoplay: 2000,
      autoplayDisableOnInteraction: false,
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      effect: "coverflow",
      centeredSlides: true,
      slidesPerView: "auto",
      coverflow: {
        rotate: 0, // 旋转的角度
        stretch: 5, // 拉伸   图片间左右的间距和密集度
        depth: 30, // 深度   切换图片间上下的间距和密集度
        modifier: 10, // 修正值 该值越大前面的效果越明显
        // slideShadows : false// 页面阴影效果
      },
      onInit: function (swiper) {
        swiperAnimate(swiper);
        swiper.stopAutoplay();
      },
    });

    var mySwiper = new Swiper(".swiper-container", {
      pagination: ".swiper-pagination",
      direction: "vertical",
      slidesPerView: "auto",
      paginationClickable: true,
      spaceBetween: 0,
      mousewheelControl: true,
      resistanceRatio: 0,
      onInit: function (swiper) {
        swiperAnimate(swiper);

        function Ani(btn, page) {
          $(btn).on("click", function () {
            mySwiper.slideTo(page, 1000, true);
          });
        }

        function navLink() {
          for (var i = 6; i >= 0; i--) {
            Ani(".nav-link-0" + i + " .link-a", i);
          }
        }
        navLink();
      },
      onSlideChangeEnd: function (swiper) {
        swiper.activeIndex >= 2
          ? $(".rocket").fadeIn()
          : $(".rocket").fadeOut();
        swiperAnimate(swiper);
        swiper.activeIndex !== 0
          ? $(".header").addClass("header-shadow")
          : $(".header").removeClass("header-shadow");
        $(".nav-link").removeClass("on");
        $(".nav-link-0" + swiper.activeIndex + "").addClass("on");

        if (swiper.activeIndex == 2) {
          mySwiper2.startAutoplay();
        }
      },
    });

    if (go_page !== undefined) {
      mySwiper.slideTo(go_page - 1, 1000, true);
    }

    $("body").on("click", ".rocket", function (event) {
      var _this = $(this);
      _this.addClass("rocket-ani");
      mySwiper.slideTo(0, 1000, true);
      setTimeout(function () {
        _this.fadeOut();
      }, 1000);
      setTimeout(function () {
        _this.removeClass("rocket-ani");
      }, 1500);
    });
  },
  // 获取当前hash的具体参数
  getQueryVariable: function (variable) {
    var query = window.location.href.split("?")[1];
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  },
  // 下载地址
  downUrl: function () {
    var googleUrl =
      "https://play.google.com/store/apps/details?id=com.qcplay.snail.android.sea";
    var iosUrl = "https://apps.apple.com/app/id6474017774";

    $(".google-btn").attr("href", googleUrl);
    $(".app-btn").attr("href", iosUrl);
  },
  // bl埋点
  countNum: function (data) {
    setTimeout(() => {
      try {
        __bl.sum(data);
      } catch (e) {
        console.log(e);
      }
    }, 500);
  },
};
